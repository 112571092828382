
@import url("https://hello.myfonts.net/count/3ab588");
@import 'reset-css/reset.css';

@font-face {
  font-family: 'Sang-Bleu-Sunrise';
  src: url('../webfonts/SangBleuSunrise-Regular-WebS.woff2') format('woff2'), url('../webfonts/SangBleuSunrise-Regular-WebS.woff') format('woff');
}

@font-face {
  font-family: 'Mont-Bold';
  src: url('../webfonts/3AB588_1_0.eot');
  src: url('../webfonts/3AB588_1_0.eot?#iefix') format('embedded-opentype'), url('../webfonts/3AB588_1_0.woff2') format('woff2'), url('../webfonts/3AB588_1_0.woff') format('woff'), url('../webfonts/3AB588_1_0.ttf') format('truetype');
}

@import './assets.scss';

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

::selection {
  background: rgba(0, 0, 0, 0.99);
  color: white;
  text-shadow: none;
}

.is-selection-inverted {
  *::selection {
    background: rgba(255, 255, 255, 0.99);
    color: black;
    text-shadow: none;
  }
}

.is-selection-normal {
  *::selection {
    background: rgba(0, 0, 0, 0.99);
    color: white;
    text-shadow: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include title;
}

b,
strong {
  @include fontTitle();
}

p {
  margin-bottom: var(--s-p-margin);

  &.no-margin {
    margin-bottom: 0;
  }

  &.margin-top {
    margin-bottom: 0;
    margin-top: var(--s-p-margin);
  }
}

a {
  color: var(--c-text);
  text-decoration: none;

  .is-inverted & {
    color: var(--c-text-inverted);
  }
}

html,
body {
  height: 100%;
  min-height: 100%;
}

html {
  @include fontRunText;

  font-size: 1vw;
}

body {
  font-size: var(--s-text);
  line-height: var(--s-line-height);
  color: var(--c-text);
  overflow-x: hidden;
  background-color: var(--c-body);

  &.is-dark {
    --c-text: rgba(255, 255, 255, 1);
    --c-text-inverted: black;
    --c-lines: rgba(255, 255, 255, 1);
    --c-lines-inverted: black;

    background-color: black;
  }
}

.font-compensated {
  font-size: calc(var(--s-text) * var(--s-text-title-font-compensated));
}

.play-button {
  --s-dimensions: calc(var(--s-gutter) * 2);
  --s-icon: 20%;
  --c-bt-bg: var(--c-lines);
  --c-bt-lines: var(--c-lines-inverted);

  $root: #{&};

  width: 100%;
  height: 100%;
  z-index: 200;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  transition: opacity ease-out 0.2s;

  @include viewport('mobile-l') {
    --s-dimensions: calc(var(--s-gutter) * 3.5);
  }

  @include hover() {
    &:hover {
      @include twerk-anime();
    }
  }

  &.is-displayed {
    opacity: 1;
  }

  &__el {
    display: block;
    position: absolute;
    cursor: pointer;
    font-size: 0;
    text-decoration: none;
    width: var(--s-dimensions);
    height: var(--s-dimensions);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    border-radius: 100%;
    background-color: var(--c-bt-bg);

    &:focus {
      outline: none;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--c-bt-lines);
      border-radius: 100%;
      border: var(--s-lines) solid var(--c-bt-bg);
      box-sizing: border-box;
      transform: scale(0);
      transition: transform var(--e-out) 0.35s;

      @include hover() {
        #{$root}:hover & {
          transform: scale(1);
        }
      }
    }
  }

  &__icon {
    position: absolute;
    width: var(--s-icon);
    height: var(--s-icon);
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    border-top: var(--s-lines) var(--c-bt-lines) solid;
    border-right: var(--s-lines) var(--c-bt-lines) solid;
    transform: translate(-70%, -50%) rotate(45deg);
    transition: border-color ease-out 0.25s;

    @include hover() {
      #{$root}:hover & {
        border-color: var(--c-bt-bg);
      }
    }
  }
}

.close-button {
  --s-dims: 2em;
  --s-icon: 1.6em;
  --c-square: var(--c-text);
  --c-arrow: var(--c-lines);

  $root: #{&};

  width: var(--s-dims);
  height: var(--s-dims);

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-left: -1px;
    background-color: var(--c-square);
    transform: scaleY(0);
    transform-origin: top left;
    transition: transform var(--e-out) 0.35s;
  }

  @include hover() {
    &:hover {
      --c-arrow: white;

      @include twerk-anime();

      &::before {
        transform-origin: bottom left;
        transform: scaleY(1);
      }
    }
  }

  .is-inverted & {
    --c-arrow: var(--c-lines-inverted);
  }

  &--black {
    --c-square: white;

    &::after {
      z-index: -1;
      transform: scale(1);
      background-color: black;
    }

    @include hover() {
      &:hover {
        --c-arrow: black;
      }
    }
  }

  &__link {
    display: block;
    position: absolute;
    width: var(--s-dims);
    height: var(--s-dims);
  }

  &__icon {
    display: block;
    position: relative;
    pointer-events: none;
    width: var(--s-icon);
    height: var(--s-icon);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);

    &::after {
      transform: rotate(90deg);
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: var(--s-icon);
      height: var(--s-lines);
      background-color: var(--c-arrow);
      transition: background-color ease-out 0.25s;
    }
  }
}

.arrow {
  --s-width: 3em;
  --s-height: 0.75em;
  --c: var(--c-text);
  --d: 0.4s;
  --e: var(--e-inout);

  position: relative;
  background: var(--c);
  height: var(--s-lines);
  width: var(--s-width);
  transition: width var(--e) var(--d);

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: var(--s-lines);
    width: var(--s-height);
    background: var(--c);
    transition: width var(--e) var(--d);
    transition-delay: 0.3s;
  }

  &::before {
    transform: rotate(-45deg);
    transform-origin: left bottom;
  }

  &::after {
    transform: rotate(45deg);
    transform-origin: left top;
  }
}

.logo {
  --s-rotate: 0deg; // -45deg;
  --s-translateX: 0%; // -10% with rotation
  --s-translateY: -3%; // -10% with rotation
  --s-dim: calc(var(--s-logo) * 1.7);
  --s-margin-t: calc((var(--s-dim) - var(--s-logo)) * -0.5);
  --s-margin-l: calc(var(--s-margin-t) * 1.2);

  $root: #{&};

  position: fixed;
  z-index: 1;
  cursor: pointer;
  top: calc(var(--s-gutter) + var(--s-margin-t));
  left: calc(var(--s-gutter) + var(--s-margin-l));
  transform: translate(var(--s-translateX), var(--s-translateY));

  .is-dark & {
    z-index: 2000;
  }

  &__container {
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: var(--s-dim);
      height: var(--s-dim);
      top: 0;
      left: 0;
      background-color: var(--c-text);
      z-index: -1;
      transform: scaleY(0);
      transform-origin: top left;
      transition: transform var(--e-inout) 0.25s;

      @include hover() {
        #{$root}:hover & {
          transform: scaleY(1);
          transform-origin: bottom left;
        }
      }
    }

    &::after {
      z-index: -2;
      background-color: transparent;
      transform: none;
    }

    @include hover() {
      &:hover {
        @include twerk-anime();
      }
    }
  }

  &__link {
    display: block;
    width: var(--s-dim);
    height: var(--s-dim);
  }

  &__el {
    display: block;
    position: relative;
    top: calc(var(--s-margin-t) * -1);
    left: calc(var(--s-margin-l) * -1);
    height: var(--s-logo);
    width: auto;
    fill: var(--c-text);
    transform: rotate(var(--s-rotate));
    transform-origin: center center;
    transition: fill ease-out 0.2s, transform var(--e-inout) 0.35s;

    @include hover() {
      #{$root}:hover & {
        --c-text-inverted: var(--c-body-hex);

        fill: var(--c-text-inverted);
        transform: rotate(0deg);
      }
    }

    @include viewport('mobile-l') {
      height: calc(var(--s-logo) * 0.9);
    }
  }

  &__p1 {
    opacity: 0;
    transition: opacity ease-out 0.25s;

    #{$root}:hover & {
      opacity: 1;
    }
  }
}

.dash {
  --s-top: calc(var(--s-line-height) * 0.45em);

  color: rgba(0, 0, 0, 0);
  display: inline-block;
  position: relative;
  width: 0.5em;
  font-size: 1em;

  &--spaced {
    margin: 0 0.2em;
  }

  &--title {
    --s-top: 0.5em;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: var(--s-lines);
    top: var(--s-top);
    background-color: var(--c-lines);

    .is-inverted & {
      background-color: var(--c-lines-inverted);
    }
  }
}

.small-title {
  @include title();

  display: block;
  line-height: var(--s-line-height-titles-small);
  padding-bottom: 0.3em;
}

@mixin text-link {
  --s-padding: -0.1em;

  display: inline-block;
  position: relative;
  transition: color ease-out 0.25s;
  text-decoration: underline;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: var(--s-padding);
    bottom: calc(var(--s-padding) + 0.05em);
    right: calc(var(--s-padding) * 4);
    left: calc(var(--s-padding) * 4);
    background-color: var(--c-text);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: top right;
    transition: transform var(--e-inout) 0.25s;

    .is-inverted & {
      background-color: white;
    }
  }

  @include hover() {
    &:hover {
      @include twerk-anime();

      color: var(--c-text-inverted);
      text-decoration: none;

      .is-inverted & {
        color: black;
      }

      &::before {
        transform: scaleX(1);
        transform-origin: top left;
      }
    }
  }
}

.text-link {
  @include text-link();
}

.cta {
  @include text-link();
  @include aliased();

  --c-text: white;
  --c-bg: black;

  padding: 1.5em 2em 1.4em 2em;
  text-decoration: none;
  color: var(--c-text);

  @include hover() {
    &:hover {
      color: var(--c-bg);
    }
  }

  &::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    background-color: var(--c-bg);
    z-index: -2;
  }

  &--small {
    font-size: var(--s-text-small);
  }

  &--inverted {
    @include anti-aliased();

    --c-text: black;
    --c-bg: white;
  }
}

.zoom-icon {
  --s-dimensions: calc(var(--s-gutter) * 0.75);
  --t-icon: translate(-50%, -1px);
  --d-transform: 0.2s;

  $root: #{&};

  position: relative;
  width: var(--s-dimensions);
  height: var(--s-dimensions);

  @include viewport('mobile') {
    --s-dimensions: calc(var(--s-gutter) * 1.2);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: var(--s-dimensions);
    height: var(--s-dimensions);
    border-radius: 50%;
    border: var(--s-lines) var(--c-lines) solid;
    box-sizing: border-box;
    background-color: var(--c-lines-inverted);
    transform: rotate(45deg); //rotate to get smooth edges on each resolution

    .is-inverted & {
      border-color: var(--c-lines-inverted);
      background-color: var(--c-lines);
    }
  }

  &.is-inverted {
    &::before {
      border-color: var(--c-lines-inverted);
      background-color: var(--c-lines);
    }
  }

  &__el {
    width: var(--s-dimensions);
    height: var(--s-dimensions);

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 50%;
      height: var(--s-lines);
      top: 50%;
      left: 50%;
      transform: var(--t-icon);
      background-color: var(--c-lines);
    }

    &::before {
      transition: transform var(--e-out) var(--d-transform);
    }

    &::after {
      transform: var(--t-icon) rotate(90deg);
    }

    .is-inverted &,
    #{$root}.is-inverted & {
      &::before,
      &::after {
        background-color: var(--c-lines-inverted);
      }
    }

    #{$root}.is-global.is-zoomed & {
      &::before {
        transform: var(--t-icon) scaleX(0);
      }
    }
  }

  &.is-active {
    --d-transform: 0.5s;
  }

  &.is-global {
    --s-decal-x: 50%;
    --s-decal-y: 50%;

    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translate(var(--s-decal-x), var(--s-decal-x)) scale(0) rotate(360deg);
    transition: transform var(--e-in) var(--d-transform), opacity linear var(--d-transform), visibility linear 0s var(--d-transform);

    &.is-active,
    &.is-zoomed {
      opacity: 1;
      visibility: visible;
      transform: translate(var(--s-decal-x), var(--s-decal-x)) scale(1) rotate(0deg);
      transition-timing-function: var(--e-out), linear;
      transition-delay: 0s;
    }

    &.is-zoomed {
      transform: translate(var(--s-decal-x), var(--s-decal-x)) scale(1) rotate(-90deg);
    }

    .is-touch & {
      --s-decal-x: 0;
      --s-decal-y: 0;

      top: var(--s-gutter);
      left: auto;
      right: var(--s-gutter);
    }
  }
}

.zoom-icon-container {
  --pos-x: 0;
  --pos-y: 0;

  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  pointer-events: none;
  transform: translate(var(--pos-x), var(--pos-y));

  .is-touch & {
    display: none;
  }
}

.preloader {
  --c-line: white;

  position: relative;
  margin: 0 auto;
  width: var(--s-preloader);

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &--inverted {
    --c-line: black;
  }

  &__svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    animation: preloader-rotate 2s linear infinite;
    transform-origin: center center;
  }

  &__path {
    stroke: var(--c-line);
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: var(--s-preloader-line);
    animation: preloader-dash 1.5s ease-in-out infinite;
  }
}

@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes preloader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.deco-image {
  --opacity: 1;

  pointer-events: none;
  opacity: 0;
  transition: opacity ease-out 0.3s;

  &.is-loaded {
    opacity: var(--opacity);
  }

  &__el {
    display: block;
    width: 100%;

    &.is-hidden {
      display: none;
    }
  }
}

.media-gif {
  --c-border: rgba(0, 0, 0, 0.1);

  $root: #{&};

  position: relative;
  pointer-events: none;
  min-height: calc(var(--s-gutter) * 2 + var(--s-preloader));
  outline: var(--s-lines) solid var(--c-border);
  transition: outline-color linear 0.2s, min-height linear 0s 0.2s;

  &.is-loaded {
    outline-color: transparent;
    min-height: 0;
    pointer-events: auto;
    cursor: pointer;
  }

  &__el {
    position: absolute;
    display: block;
    width: 100%;
    max-width: 100%;
    visibility: hidden;
    transition: opacity linear 0.2s 0.2s;

    #{$root}.is-loaded & {
      position: relative;
      visibility: visible;
    }
  }

  &__additional-el {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    visibility: hidden;

    &.is-active {
      visibility: visible;
    }
  }

  &__preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity linear 0.2s, visibility 0s 0.2s;

    #{$root}.is-loaded & {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.media-image {
  --c-border: rgba(0, 0, 0, 0.1);
  --d-zooming: 0.5s;
  --outline-final: var(--s-lines) solid transparent;

  $root: #{&};

  position: relative;
  min-height: calc(var(--s-gutter) * 2 + var(--s-preloader));
  outline: var(--s-lines) solid var(--c-border);
  transition: outline-color linear 0.2s, min-height linear 0s 0.2s;

  &.is-loaded {
    outline: var(--outline-final);
    min-height: 0;
  }

  &.is-zoomable {
    cursor: pointer;
  }

  .is-inverted & {
    --c-border: rgba(255, 255, 255, 0.1);
  }

  &__el {
    position: absolute;
    display: block;
    max-width: 100%;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity linear 0.2s 0.2s;

    #{$root}.is-loaded & {
      position: relative;
      visibility: visible;
      opacity: 1;
    }
  }

  &__preloader {
    --c-line: black;

    position: absolute;
    left: 50%;
    top: var(--s-gutter);
    transform: translateX(-50%);
    transition: opacity linear 0.2s, visibility 0s 0.2s;

    #{$root}.is-loaded & {
      opacity: 0;
      visibility: hidden;
    }

    .is-inverted & {
      --c-line: white;
    }
  }

  &__zoom-icon {
    --s-margin: calc(var(--s-gutter) * 0.5);

    position: absolute;
    display: none;
    top: var(--s-margin);
    right: 0;
    transform: translateX(50%);
    transition: opacity ease-out 0.2s var(--d-zooming);

    #{$root}.is-zoomed & {
      opacity: 0;
    }

    #{$root}.is-zoomable & {
      .is-touch & {
        display: block;
      }
    }
  }
}

.media-image-zoomed {
  --s-scale-dezoomed: 1;
  --s-scale-zoomed: 1;
  --d-zooming: 0.5s;

  visibility: hidden;
  cursor: pointer;
  transition: transform var(--e-out) var(--d-zooming), visibility linear 0s var(--d-zooming);
  transform: scale(var(--s-scale-dezoomed));
  will-change: transform, visibility;

  &.is-active {
    transform: scale(var(--s-scale-zoomed));
    transition-delay: 0s;
    visibility: visible;
  }

  &.is-hidden {
    display: none;
  }
}

.media-image-zoomed-container {
  --pos-x: 0;
  --pos-y: 0;

  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 1000;
  transform: translate(var(--pos-x), var(--pos-y));
  will-change: transform;

  &.deactived {
    display: none;
  }
}

.media-image-zoomed-background {
  --d-fading: 0.5s;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  visibility: hidden;
  background-color: black;
  opacity: 0;
  transition: opacity ease-out var(--d-fading) 0s, visibility linear 0s calc(var(--d-fading));

  &.is-active {
    cursor: zoom-out;
    opacity: 1;
    visibility: visible;
    transition-delay: 0s, 0s;
  }
}

.media-video {
  $root: #{&};

  position: relative;

  &__el {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    transition: opacity linear 0.2s;

    #{$root}.is-loading & {
      opacity: 0.35;
    }
  }

  &__preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity linear 0.2s, visibility 0s 0.2s;
    opacity: 0;
    visibility: hidden;

    #{$root}.is-loading & {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

.navigation {
  @include fontTitle;
  @include label;

  --c-text-inverted: var(--c-body-hex);
  --c-nav-text: var(--c-text);
  --c-nav-text-hover: var(--c-text-inverted);

  position: fixed;
  opacity: 0;
  z-index: 999;
  bottom: var(--s-gutter);
  left: var(--s-gutter);
  font-size: var(--s-text-navigation);
  line-height: 1;
  transform: rotate(-90deg) translate(0, 100%);
  transform-origin: bottom left;

  &.is-ready {
    opacity: 1;
  }

  &.is-hidden {
    display: none;
  }

  &.is-mobile {
    --c-nav-text: var(--c-text-inverted);
    --c-nav-text-hover: var(--c-text);
    --d-mobile-transition: 0.25s;
    --e-mobile-transition: var(--e-inout);

    transform: rotate(0deg) translate(0, 0);
    left: 0;
    bottom: 0;
    height: 0;
  }

  &.is-mobile-opened {
    --d-mobile-transition: 0.5s;
    --e-mobile-transition: var(--e-out);
  }

  &__container {
    display: flex;
    flex-direction: row-reverse;

    .is-vertical &,
    .is-mobile & {
      display: block;
    }

    .is-mobile & {
      pointer-events: none;
      width: 100vw;
      padding: var(--s-gutter);
      padding-bottom: calc(var(--s-gutter) * 2);
      box-sizing: border-box;
      transition: transform var(--e-mobile-transition) var(--d-mobile-transition);
      will-change: transform;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: black;
        z-index: -2;
      }
    }

    .is-mobile-opened & {
      pointer-events: auto;
      transform: translateY(-100%);
    }
  }

  &__link {
    --s-padding: -0.5em;
    --t-normal: color ease-out 0.25s;
    --t-mobile: opacity ease-out 0.25s, transform var(--e-out) 0.35s;

    display: inline-block;
    position: relative;
    color: var(--c-nav-text);
    margin-right: 4.5vh;
    transition: var(--t-normal);

    @include viewport('vertical') {
      margin-right: 3vh;
    }

    &:first-child {
      margin-right: 0;
    }

    &::before,
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: var(--s-padding);
      bottom: calc(var(--s-padding) + 0.2em);
      right: var(--s-padding);
      left: var(--s-padding);
      background-color: var(--c-nav-text);
      z-index: -1;
      transform: scaleX(0);
      transform-origin: top right;
      transition: transform var(--e-inout) 0.25s;
    }

    &::before {
      body.windows.chrome &,
      body.windows.firefox & {
        top: calc(var(--s-padding) + 0.1em);
        bottom: calc(var(--s-padding) + 0.05em);
      }
    }

    &::after {
      left: 0;
      right: 0;
      top: 0.95em;
      bottom: auto;
      height: var(--s-lines);

      body.windows.chrome &,
      body.windows.firefox & {
        top: 1.1em;
      }
    }

    @include hover() {
      &:hover {
        @include twerk-anime();

        color: var(--c-nav-text-hover);

        &::before {
          transform: scaleX(1);
          transform-origin: top left;
        }
      }
    }

    &.is-current {
      cursor: default;

      &::before {
        transform-origin: top right;
      }

      &::after {
        transform: scaleX(1);
      }

      @include hover() {
        &:hover {
          color: var(--c-nav-text);
          animation: none;

          &::before {
            transform: scaleX(0);
          }
        }
      }
    }

    .is-vertical &,
    .is-mobile & {
      display: table;
      margin-bottom: 1.25em;
      margin-right: 0;
    }

    .is-mobile & {
      margin-bottom: 1.5em;
      opacity: 0;
      transform: translateY(100%);
      transition: var(--t-normal), var(--t-mobile);
    }

    .is-mobile-opened & {
      opacity: 1;
      transform: translateY(0);

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          --d-transition: calc(#{$i} * 0.1s);

          transition-delay: 0s, var(--d-transition), var(--d-transition);
        }
      }
    }
  }

  &__burger {
    --w-el: var(--s-burger-w);
    --y-el: calc(var(--s-lines) * 4);
    --s-burger: calc(var(--w-el) + var(--s-gutter) * 0.5);
    --decal-x-burger: calc((var(--s-burger) - var(--w-el)) * -0.5);
    --decal-y-burger: calc((var(--s-burger) - var(--y-el) * 2 - var(--s-lines) * 2) * -0.5);

    display: none;
    position: fixed;
    z-index: 100;
    cursor: pointer;
    width: var(--s-burger);
    height: var(--s-burger);
    bottom: var(--s-gutter);
    left: var(--s-gutter);
    margin-left: var(--decal-x-burger);
    margin-bottom: var(--decal-y-burger);

    &-el {
      --d-lines: 0.3s;

      width: var(--w-el);
      height: var(--s-lines);
      margin-top: 50%;
      margin-left: 50%;
      background-color: var(--c-lines);
      transform: translate(-50%, 0);
      transition: background-color linear var(--d-lines);

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: var(--w-el);
        height: var(--s-lines);
        margin-top: calc(var(--y-el) * -1);
        background-color: var(--c-lines);
        transition: background-color linear var(--d-lines), transform var(--e-inout) var(--d-lines), margin-top var(--e-inout) var(--d-lines);
        transform-origin: center center;
      }

      &::after {
        margin-top: var(--y-el);
      }

      .is-mobile-opened & {
        --c-lines: var(--c-nav-text);

        background-color: transparent;

        &::before {
          transform: rotate(45deg);
          margin-top: 0;
        }

        &::after {
          transform: rotate(135deg);
          margin-top: 0;
        }
      }
    }

    .is-mobile & {
      display: block;
    }
  }

  &__mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    z-index: -2;
  }
}

.content-page {
  --w-mobile-s: calc(100vw - var(--s-logo) - var(--s-gutter) * 1.7);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
  margin-left: 50vw;
  min-height: 100vh;
  padding-top: var(--s-gutter);
  padding-right: var(--s-gutter);
  box-sizing: border-box;

  @include viewport('mobile-layout') {
    display: block;
    width: 75vw;
    margin-left: 25vw;
  }

  @include viewport('mobile-l') {
    width: var(--w-mobile-s);
    margin-left: calc(100vw - var(--w-mobile-s));
  }

  &__page-title {
    --s-decal-y: calc(var(--s-gutter) * 0.35);
    --d-transition: 0.15s;

    @include label-small;

    position: fixed;
    z-index: 1;
    top: calc(var(--s-gutter) - 0.1em);
    left: 25vw;

    @include viewport('mobile-layout') {
      --s-decal-y: 0;

      position: relative;
      top: 0;
      left: 0;
    }

    &__el {
      display: block;
      transition: opacity var(--e-out) var(--d-transition), transform var(--e-out) var(--d-transition);

      .is-hidden > & {
        opacity: 0;
        transform: translateY(calc(var(--s-decal-y) * -1));
      }

      .is-jumping > & {
        transition-duration: 0s;
      }

      .is-below > & {
        transform: translateY(var(--s-decal-y)) !important;
      }
    }
  }

  &__lead {
    // @include hyphens();

    position: relative;
    font-size: var(--s-text-lead);
    line-height: var(--s-line-height-lead);
    margin-top: -0.4em;
    margin-bottom: 2.5em;
    letter-spacing: -0.0025em;

    @include viewport('mobile-layout') {
      margin-bottom: 1.2em;
      margin-top: 1.5em;
    }
  }

  &__3-4 {
    position: relative;
    left: -25vw;
    width: 75vw;

    @include viewport('mobile-layout') {
      left: 0;
      width: auto;
      margin-right: calc(var(--s-gutter) * -1);
    }
  }

  &__subtitle {
    @include label-small;

    position: relative;
    margin-top: 7em;
    margin-bottom: 5.5em;

    @include viewport('mobile-layout') {
      margin-bottom: 4.75em;
    }

    @include viewport('mobile-s') {
      margin-bottom: 3.75em;
    }

    &:first-child {
      margin-top: 0;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.4em;
      height: var(--s-lines);
      background-color: var(--c-lines);
    }
  }

  &__list {
    margin-top: 3em;

    @include viewport('mobile-layout') {
      margin-top: 2.7em;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .text-link {
    --c-text-inverted: var(--c-body-hex);
  }

  .cta {
    --c-text: var(--c-body-hex);
  }
}

.info {
  &__image {
    &-el {
      min-height: calc(75vw * 9 / 16);
    }
  }

  &__introduction {
    @include text-indent();
    // @include hyphens();
    @include justify();

    letter-spacing: -0.0025em;
    margin-top: var(--s-gutter);

    @include viewport('mobile') {
      font-size: var(--s-text-small);
    }
  }

  &__cv {
    margin-top: 2.5em;
  }

  &__steps-listing {
    display: flex;

    @include viewport('mobile-layout') {
      flex-wrap: wrap;
    }
  }

  &__step-infos {
    flex-basis: 75%;

    @include viewport('mobile-layout') {
      flex-basis: 100%;
    }

    .dash {
      --s-top: 0.75em;
    }
  }

  &__step-date {
    flex-basis: 25%;
    text-align: right;
    position: relative;
    margin-top: -0.15em;
    font-size: var(--s-text-small);

    @include viewport('mobile-layout') {
      flex-basis: 100%;
      text-align: left;
      margin-top: 1em;
    }
  }

  &__step-title {
    @include viewport('mobile-layout') {
      padding-bottom: 0;
    }
  }

  &__step-additional {
    display: block;
    font-size: var(--s-text-small);
    padding-top: 2em;
    padding-left: 3.2em;

    @include viewport('mobile-layout') {
      padding-top: 1em;
      padding-left: 0;
    }
  }

  &__expertise-text {
    @include hyphens();
    @include justify();

    @include viewport('mobile') {
      font-size: var(--s-text-small);
      margin-top: 0.8em;
    }
  }
}

.contact {
  &__video {
    width: 100%;
    height: auto;
  }

  &__address {
    font-size: var(--s-text-lead);
    line-height: var(--s-line-height-lead);
    margin-top: calc(var(--s-gutter) * 0.85);
  }
}

.frontend {
  &__git {
    margin-bottom: 3em;
  }
}

.frontend-link {
  $root: #{&};

  text-decoration: none;
  margin-bottom: 3em;

  &__infos {
    @include fontTitle();

    display: block;
    font-size: var(--s-text-small);
    text-decoration: none;
    margin-bottom: 0.5em;
  }

  &__detail {
    @include fontRunText();

    font-size: 0.9em;
  }

  &__url {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;

    @include hover() {
      #{$root}:hover & {
        text-decoration: none;
      }
    }
  }
}

.home {
  --h-stage: calc(100vh - var(--s-gutter) * 2);

  @include viewport('mobile-layout') {
    --h-stage: calc(100% - var(--s-gutter) * 2);

    position: relative;
    height: 100vh;

    .animate-in {
      --delay-id: 2;
    }

    .animate-in__s2 {
      --delay-id: 1;
    }
  }

  &__title {
    --s-left-mobile: calc(var(--s-burger-w) * 0.5) - 0.6em;

    @include label-small;

    position: fixed;
    left: 25vw;
    top: calc(var(--s-gutter) - 0.1em);
    width: 20vw;
    transform-origin: top left;

    @include viewport('mobile-layout') {
      position: fixed;
      left: calc(var(--s-gutter) + var(--s-left-mobile));
      top: auto;
      bottom: calc(var(--s-gutter) * 1.9);
      width: calc(100vh - var(--s-gutter) * 6);
      transform: rotate(-90deg) !important;

      @media (min-aspect-ratio: 12/8) {
        display: none;
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  &__title-name {
    @include label-small;

    letter-spacing: 0.01em;
  }

  &__title-detail {
    @include fontRunText();

    display: block;
    font-size: var(--s-content-page-title-detail);
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: 0.03em;
    padding-top: 0.5em;
    transform: translate(-0.02em, -0.1em);

    @include viewport('mobile-layout') {
      display: inline;
      padding-left: 1em;
    }
  }

  &__stage {
    $root: #{&};

    position: relative;
    display: block;
    width: 50vw;
    height: var(--h-stage);
    overflow: hidden;

    @include viewport('mobile-layout') {
      position: fixed;
      top: var(--s-gutter);
      width: 100%;
    }

    &-overlay {
      --s-margin: calc(var(--s-gutter) * 2);

      position: absolute;
      display: none;
      top: var(--s-margin);
      bottom: var(--s-margin);
      left: var(--s-margin);
      right: var(--s-margin);
      z-index: 1;
      text-align: center;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: black;
        transform: scaleY(0);
        transform-origin: top left;
        transition: transform var(--e-inout) 0.25s;

        @include hover() {
          #{$root}:hover & {
            transform: scaleY(1);
            transform-origin: bottom left;
          }
        }
      }

      @include hover() {
        display: block;

        #{$root}:hover & {
          @include twerk-anime();
        }
      }

      @include viewport('tablet-s') {
        display: none;
      }
    }

    &-title {
      @include fontTitle();
      @include anti-aliased();

      color: white;
      position: absolute;
      top: 50%;
      opacity: 0;
      transform: translate(-50%, 0);
      transition: opacity var(--e-inout) 0.25s, transform var(--e-inout) 0.25s;
      transition-delay: 0.15s;

      @include hover() {
        #{$root}:hover & {
          opacity: 1;
          transform: translate(-50%, -2em);
          transition-timing-function: var(--e-out);
        }
      }
    }

    &-arrow {
      --s-width: 2em;
      --s-height: 0.5em;
      --c: var(--c-text);
      --t: translate(-50%, 1em);
      --c: var(--c-text-inverted);
      --s-width: 0;
      --s-height: 0;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: var(--t) scaleX(-1);

      @include hover() {
        #{$root}:hover & {
          --s-width: 2.8em;
          --s-height: 0.65em;
        }
      }
    }
  }

  &__video {
    --w: auto;
    --h: calc(var(--h-stage) + 1px);
    --x: 0;
    --y: 0;

    position: absolute;
    left: var(--x);
    top: var(--y);
    width: var(--w);
    height: var(--h);
  }
}

.projects {
  --d-opacity: 0.35s;
  --top-dash: 0.3em;
  --top-dash-windows: 0.45em;

  overflow: hidden;

  &__title {
    .dash {
      --s-top: var(--top-dash);

      body.windows.chrome &,
      body.windows.firefox & {
        --s-top: var(--top-dash-windows);
      }
    }

    @include viewport('mobile-layout') {
      margin-bottom: 0.35em;
    }
  }

  &__subtitle {
    position: absolute;
    top: -1.2em;

    @include viewport('tablet-s') {
      position: relative;
      margin-bottom: 0;
      top: -0.5em;
    }

    .dash {
      --s-top: var(--top-dash);

      body.windows.chrome &,
      body.windows.firefox & {
        --s-top: var(--top-dash-windows);
      }
    }
  }

  &__year-container {
    position: relative;
  }

  &__year {
    @include fontRunText();

    font-size: var(--s-content-page-title-detail);
  }
}

.project-list-container {
  @include viewport('tablet-s') {
    padding-bottom: calc(var(--s-gutter) * 2);
  }
}

.project-list {
  $root: #{&};

  position: relative;
  border-top: var(--s-lines) solid var(--c-lines);
  margin-right: calc(var(--s-gutter) * -1);

  &:first-child {
    border-top: 0;
  }

  &.is-first {
    border-top: var(--s-lines) solid var(--c-lines);
  }

  &__link {
    --s-padding: 2.2em;
    --s-padding-bottom-cor: 0.3em;

    display: block;
    padding-top: var(--s-padding);
    padding-bottom: calc(var(--s-padding) - var(--s-padding-bottom-cor));
    padding-left: 0;
    padding-right: var(--s-gutter);

    @include viewport('tablet-s') {
      --s-padding: 1.75em;
      --s-padding-bottom-cor: 0.5em;
    }

    @include viewport('mobile') {
      --s-padding: 1.35em;
      --s-padding-bottom-cor: 0.25em;
    }
  }

  &__title {
    font-size: var(--s-text-h2);
    transition: color ease-out 0.2s;

    @include hover() {
      #{$root}:hover & {
        @include twerk-anime();
      }
    }
  }

  &__thumb {
    --s-width: calc(75vw * 0.5);
    --s-left-1: -25vw;
    --s-left-2: calc(50vw - var(--s-width) + 2px);
    --s-scale: 0.75;
    --s-scaleHover: 1;
    --s-translateY: 0;
    --s-posY: 0;
    --s-posYMargin: calc(var(--s-gutter) * 0.2);
    --d-animation-in: 0.2s;
    --d-animation-in-delay: 0.25s;
    --d-animation-in-delay-randomizer: 1;
    --d-scaleHover: 0.35s;
    --d-imageEffect: 0.5s;
    --d-imageEffectDelay: 0.2s;
    --e-scaleHover: var(--e-inout);
    --t-transform: transform var(--e-scaleHover) var(--d-scaleHover);

    @include viewport('mobile-layout') {
      --s-width: 50vw;
      --s-left-1: -25vw;
      --s-left-2: calc(25vw + 2px);
    }

    pointer-events: none;
    position: absolute;
    z-index: -1;
    width: var(--s-width);
    left: var(--s-left);

    @include hover() {
      #{$root}:hover & {
        @include twerk-anime();
      }
    }

    &--01 {
      --s-left: var(--s-left-1);

      #{$root}:nth-child(2n+0) & {
        --s-left: var(--s-left-2);
      }

      top: 0;
    }

    &--02 {
      --s-left: var(--s-left-2);

      #{$root}:nth-child(2n+0) & {
        --s-left: var(--s-left-1);
      }

      bottom: 0;
    }

    &-el {
      --filter: grayscale(100%) contrast(2) brightness(2);

      position: relative;
      display: block;
      width: 100%;
      opacity: 0;
      top: calc(var(--s-posY) + var(--s-posYMargin));
      transform: translateY(var(--s-translateY)) scale3d(var(--s-scale), var(--s-scale), 1);
      transition: opacity ease-out var(--d-animation-in), var(--t-transform), filter ease-out var(--d-imageEffect);
      transition-delay: 0s, var(--d-animation-in), 0s;
      -webkit-filter: var(--filter);
      filter: var(--filter);

      &--01 {
        --s-translateY: calc(-100% - var(--s-lines));
      }

      &--02 {
        --s-translateY: 100%;

        top: calc((var(--s-posY) + var(--s-posYMargin)) * -1);
      }

      &.is-loaded {
        @include hover() {
          #{$root}:hover & {
            --s-scale: var(--s-scaleHover);

            opacity: 1;
            filter: none;
            transition-delay: var(--d-animation-in-delay), var(--d-animation-in-delay), calc(var(--d-animation-in) + var(--d-animation-in-delay) + var(--d-imageEffectDelay));
          }
        }
      }
    }
  }

  &__detail {
    padding-top: 0.5em;

    @include viewport('tablet-s') {
      padding-top: 0.25em;
    }

    @include viewport('mobile') {
      font-size: var(--s-text-small);
    }
  }

  &__separation {
    display: inline-block;
    padding: 0 0.2em;
  }
}

.project {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  z-index: 100;

  &.is-inverted {
    @include anti-aliased();

    color: var(--c-text-inverted);
  }

  &__container {
    position: relative;
    z-index: 101;
    min-height: 100vh;
  }

  &__close-button {
    $root: #{&};

    position: fixed;
    z-index: 1000;
    top: calc(var(--s-gutter) - var(--s-decal-close-to-corner));
    right: calc(var(--s-gutter) - var(--s-decal-close-to-corner));

    &::after {
      z-index: -1;
      transform: scale(1);
      background-color: var(--project-color);
      transition: background-color linear 0.25s;
    }

    &.is-transparent {
      &::after {
        background-color: transparent;
      }
    }
  }

  &__cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &--reversed {
      flex-direction: row-reverse;
    }

    &--third {
      position: relative;
      margin-left: calc(var(--s-gutter-col) * -1);
      margin-right: calc(var(--s-gutter-col) * -1);
    }

    @include viewport('tablet-s') {
      display: block;
    }
  }

  &__col-left,
  &__col-right,
  &__col-full {
    position: relative;
    flex-basis: 50%;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  &__col-left {
    padding-right: var(--s-gutter-col);

    &--inverted {
      padding-right: 0;
      padding-left: var(--s-gutter-col);
    }

    @include viewport('tablet-s') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__col-right {
    padding-left: var(--s-gutter-col);

    &--inverted {
      padding-left: 0;
      padding-right: var(--s-gutter-col);
    }

    @include viewport('tablet-s') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__coll-full {
    flex-basis: 100%;
  }

  &__col-third {
    flex-basis: 33.333%;
    box-sizing: border-box;
    padding-left: var(--s-gutter-col);
    padding-right: var(--s-gutter-col);
  }

  &__col-full {
    flex-basis: 100%;
    flex-shrink: 0;
  }

  &__header {
    display: table;
    width: 100%;
    box-sizing: border-box;
    padding: var(--s-gutter);
    padding-bottom: 0;

    @include viewport('tablet-s') {
      display: block;
    }
  }

  &__title {
    display: block;
    font-size: var(--s-text-h1);
    padding-right: calc(var(--s-gutter) * 4);
    padding-top: calc(var(--s-gutter) * 2);
    padding-bottom: calc(var(--s-gutter) + 0.1em);

    @include viewport('tablet-s') {
      --delay-id: 0 !important;

      padding-top: 0;
      padding-bottom: calc(var(--s-gutter) * 1.3);
    }

    @include viewport('mobile-l') {
      padding-top: 0.25em;
    }
  }

  &__metas {
    display: table-header-group;

    @include viewport('tablet-s') {
      --delay-id: 1 !important;

      display: block;
    }
  }

  &__metas-cols {
    @include viewport('tablet-s') {
      display: flex;
    }
  }

  &__meta {
    --s-tablet-margin-top: 1.35em;
    --s-mobile-margin-top: 1em;

    margin-bottom: 0;

    &-title {
      @include label-small;

      display: block;
      letter-spacing: 0.05em;
    }

    &-desc {
      display: block;
      font-size: var(--s-text-small);

      .is-inverted & {
        letter-spacing: var(--s-letter-spacing-readability);
      }
    }

    @include viewport('tablet-s') {
      flex-basis: 33.33%;
    }

    @include viewport('mobile-l') {
      flex-basis: 100%;
      margin-top: var(--s-mobile-margin-top);
    }
  }

  &__date,
  &__agency {
    flex-shrink: 0;
    flex-basis: 15%; // 16.66%; for same sizes

    @include viewport('tablet') {
      flex-basis: 13%;
    }

    @include viewport('tablet-s') {
      flex-basis: 33%;
    }

    @include viewport('mobile-l') {
      flex-basis: 100%;
    }
  }

  &__type {
    flex-grow: 1;
  }

  &__skills {
    flex-basis: 50%;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: var(--s-gutter);
    padding-left: var(--s-gutter-col);

    @include viewport('tablet-s') {
      flex-basis: 100%;
      padding-left: 0;
      margin-top: var(--s-tablet-margin-top);
    }

    @include viewport('mobile-l') {
      flex-basis: 100%;
      padding-left: 0;
      margin-top: var(--s-mobile-margin-top);
    }
  }

  &__content {
    padding: var(--s-gutter);
    padding-bottom: calc(var(--s-gutter) * 2);
  }

  &__lead {
    @include hyphens();
    @include justify();

    padding-right: var(--s-gutter);

    @include viewport('tablet-s') {
      padding-right: 0;
    }
  }

  &__desc {
    @include text-indent();
    @include hyphens();
    @include justify();

    font-size: var(--s-text-small);
    padding-right: var(--s-gutter);
    padding-top: 1.5em;

    .is-inverted & {
      letter-spacing: var(--s-letter-spacing-readability);
    }

    @include viewport('tablet-s') {
      padding-right: 0;
    }
  }

  &__section {
    --c-background: black;
    --s-padding: var(--s-gutter);
    --s-side-margin: calc(var(--s-gutter) * -1);
    --s-bg-margin-top: 0;
    --s-bg-margin-bottom: 0;

    position: relative;
    margin-left: var(--s-side-margin);
    margin-right: var(--s-side-margin);
    padding: var(--s-padding);
    padding-top: calc(var(--s-padding) - 0.35em);

    &--padding-none {
      --s-padding: 0;
    }

    &--padding-2x {
      --s-padding: calc(var(--s-gutter) * 2);
    }

    &--padding-3x {
      --s-padding: calc(var(--s-gutter) * 3);
    }

    &--padding-4x {
      --s-padding: calc(var(--s-gutter) * 4);
    }

    &--colored {
      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: var(--s-bg-margin-top);
        bottom: var(--s-bg-margin-bottom);
        right: 0;
        left: 0;
        background-color: var(--c-background);
      }
    }

    @include viewport('vertical') {
      --s-padding: calc(var(--s-gutter) * 2);

      &--padding-none {
        --s-padding: 0;
      }
    }

    @include viewport('tablet') {
      --s-padding: calc(var(--s-gutter) * 2);

      &--padding-none {
        --s-padding: 0;
      }
    }

    @include viewport('tablet-s') {
      --s-padding: var(--s-gutter);

      padding-top: var(--s-padding);

      &--padding-none {
        --s-padding: 0;
      }
    }

    @include viewport('mobile-s') {
      padding-top: calc(var(--s-padding) + 0.5em) !important;
    }
  }

  &__pick-title {
    @include label-small;

    letter-spacing: 0.05em;
    line-height: 1.4;
  }

  &__pick-desc {
    @include text-indent();
    @include hyphens();
    @include justify();

    font-size: var(--s-text-small);
    padding-top: 0.8em;
  }

  &__thumb {
    margin-top: calc(var(--s-gutter-col) * 2);

    @include viewport('mobile-l') {
      margin-top: var(--s-gutter);
    }
  }

  &__mobile-thumbs {
    --s-gutter-col-mid: calc(var(--s-gutter-col) * 2);
    --s-mobile: 320px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: calc(3 * var(--s-mobile) + 6 * var(--s-gutter-col-mid) + 2px);
    margin: 0 auto;

    @include viewport('mobile') {
      --s-gutter-col-mid: 0;

      display: block;
    }
  }

  &__mobile-thumb {
    --s-margin-top: 0;
    --s-margin-top-mobile: var(--s-gutter);
    --box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15), 0 15px 10px rgba(0, 0, 0, 0.15);

    position: relative;
    flex-basis: 33.33%;
    padding-left: var(--s-gutter-col-mid);
    padding-right: var(--s-gutter-col-mid);
    margin-top: var(--s-margin-top);
    box-sizing: border-box;

    @include viewport('mobile') {
      padding: 0;
      margin-top: var(--s-margin-top-mobile);
    }

    &--middle {
      top: (calc(var(--s-gutter-col-mid) * 2));

      @include viewport('mobile') {
        top: 0;
      }
    }

    &--right {
      position: relative;
      top: (calc(var(--s-gutter-col-mid) * 4));

      @include viewport('mobile') {
        top: 0;
      }
    }

    &--line-2 {
      --s-margin-top: calc(var(--s-gutter-col-mid) * 2);
    }

    &--line-3 {
      --s-margin-top: calc(var(--s-gutter-col-mid) * 4);
    }

    .media-image,
    .media-image__el {
      width: 100%;
      max-width: var(--s-mobile);
    }

    .media-image {
      min-width: var(--s-mobile);
      box-shadow: var(--box-shadow);

      @include viewport('mobile') {
        min-width: 100%;
        margin: 0 auto;
      }

      &.is-loaded {
        min-width: 0;
      }
    }
  }

  &__credits {
    padding: calc(var(--s-gutter) * 2);
    padding-bottom: 0;
    text-align: center;

    @include viewport('tablet') {
      margin-top: var(--s-gutter);
    }

    @include viewport('tablet-s') {
      padding: 0;
      padding-top: calc(var(--s-gutter) * 2);
    }

    @include viewport('mobile') {
      padding-top: calc(var(--s-gutter) * 3);
    }
  }

  &__credits-title {
    @include label-small;
  }

  &__credits-desc {
    font-size: var(--s-text-small);
    padding-top: 0.7em;
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.miscs {
  &__container {
    flex-grow: 1;
  }
}

.miscs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2px;
  grid-row-gap: calc(var(--s-gutter) * 1);
  padding-bottom: 40%;
  align-items: center;

  @include viewport('mobile-layout') {
    display: block;
    padding-bottom: 0;
  }
}

.misc-list {
  --d-animation-in: 0.2s;
  --d-in-transform: 1s;
  --e-transform: var(--e-out);
  --d-fading: 0.5s;
  --s-scaleHover: 0.75;
  --d-scaleHover: 0.35s;
  --e-scaleHover: var(--e-inout);
  --s-translateHover: -1.5em;
  --s-translateHoverAddedScale: 2;
  --d-translateHover: 0.35s;
  --s-image-height: calc(75vw * 0.5);

  $root: #{&};

  &:nth-child(2n+0) {
    transform: translateY(15vw);

    @include viewport('mobile-layout') {
      transform: translateY(0);
    }
  }

  &__container {
    @include hover() {
      #{$root}:hover & {
        @include twerk-anime();
      }
    }
  }

  &__link {
    display: block;
  }

  &__preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity linear var(--d-animation-in), visibility 0s var(--d-animation-in);

    #{$root}.is-displayed & {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__img {
    --c-border-1: rgba(0, 0, 0, 0.1);
    --c-border-2: white;
    --t-transform: transform var(--e-scaleHover) var(--d-scaleHover);

    position: relative;
    min-height: var(--s-image-height);
    box-shadow: 0 0 0 var(--s-lines) var(--c-border-1), 0 0 0 var(--s-lines) var(--c-border-2);
    transition: box-shadow linear var(--d-animation-in), var(--t-transform);

    #{$root}.is-displayed & {
      --c-border-1: transparent;
      --c-border-2: transparent;
    }

    #{$root}.is-animated-in & {
      transition: var(--t-transform);
    }

    @include hover() {
      #{$root}:hover & {
        transform: scale3d(var(--s-scaleHover), var(--s-scaleHover), 1);
      }
    }
  }

  &__img-el {
    --d-delay: 0s;
    --t-filter: filter var(--e-out) calc(var(--d-fading) + var(--d-scaleHover));

    width: 100%;
    display: block;
    opacity: 0;
    transition: var(--t-filter), opacity ease-out var(--d-fading);

    #{$root}.is-displayed & {
      opacity: 1;
    }

    #{$root}.is-animated-in & {
      transition: var(--t-filter);
    }

    @include hover() {
      #{$root}:hover & {
        filter: grayscale(100%) contrast(2) brightness(2);
      }
    }
  }

  &__info {
    line-height: var(--s-line-height);
    margin-top: 1.2em;
    padding-left: 1.5em;
    transition: transform var(--e-scaleHover) var(--d-translateHover);

    @include hover() {
      #{$root}:hover & {
        transform: translateY(calc(var(--s-translateHover) - 0.75em));

        @include viewport('mobile-layout') {
          transform: translateY(calc(var(--s-translateHover)));
        }
      }
    }

    @include viewport('mobile-layout') {
      padding-left: 0;
      margin-top: 0.75em;
      margin-bottom: calc(var(--s-gutter) * 1.5);
    }

    @include viewport('mobile') {
      line-height: calc(var(--s-line-height) * 0.8);
    }

    .dash {
      --s-top: calc(var(--s-line-height) * 0.4em);

      @include viewport('mobile') {
        display: none;
      }
    }
  }

  &__date {
    @include fontRunText();

    @include viewport('mobile') {
      display: block;
    }
  }
}

.misc {
  @include anti-aliased();

  position: relative;
  z-index: 100;
  background-color: black;
  color: white;

  &.is-inverted {
    color: var(--c-text-inverted);
  }

  &__container {
    min-height: 100vh;
  }

  &__close-button {
    position: fixed;
    z-index: 1000;
    top: calc(var(--s-gutter) - var(--s-decal-close-to-corner));
    right: calc(var(--s-gutter) - var(--s-decal-close-to-corner));
  }

  &__header {
    padding: var(--s-gutter);
  }

  &__title {
    .is-ios & {
      padding-top: 0.25em;
    }

    @include viewport('tablet') {
      padding-right: var(--s-gutter);
    }

    @include viewport('mobile-l') {
      padding-right: calc(var(--s-gutter) * 2);
    }
  }

  &__lead {
    width: 50%;
    box-sizing: border-box;
    padding-top: 0.5em;

    @include viewport('tablet') {
      width: 100%;
      padding-right: var(--s-gutter);
    }

    @include viewport('mobile-l') {
      padding-right: calc(var(--s-gutter) * 2);
    }
  }

  &__links {
    padding-top: 0.5em;
  }

  &__link {
    margin-right: 1.5em;
  }

  &__medias {
    padding-top: var(--s-gutter);
    padding-left: calc(var(--s-gutter) * 2);
    padding-right: calc(var(--s-gutter) * 2);
    padding-bottom: calc(var(--s-gutter) * 2);

    @include viewport('tablet-s') {
      padding-left: var(--s-gutter);
      padding-right: var(--s-gutter);
      padding-bottom: var(--s-gutter);
    }
  }

  &__media {
    margin-top: var(--s-gutter);

    &:first-child {
      margin-top: 0;
    }

    &--margin-top-l {
      margin-top: calc(var(--s-gutter) * 2);
    }

    &--margin-top-xl {
      margin-top: calc(var(--s-gutter) * 4);
    }
  }
}

.pagination {
  $root: #{&};

  --c-hover-bg: black;
  --c-hover-text: white;
  --s-text-translate: calc(var(--s-text) * -2);

  display: flex;

  &.is-black {
    --c-hover-bg: white;
    --c-hover-text: black;
  }

  @include viewport('tablet-s') {
    --s-text-translate: calc(var(--s-text) * -0.5);

    flex-wrap: wrap;
    margin-top: var(--s-gutter);
  }
}

.pagination-link {
  $root: #{&};

  position: relative;
  flex-basis: 50%;
  text-align: center;
  box-sizing: border-box;
  padding: calc(var(--s-gutter) * 3) calc(var(--s-gutter) * 2);
  color: var(--c-hover-bg);

  @include viewport('tablet-s') {
    display: block;
    flex-basis: 100%;
    padding: calc(var(--s-gutter) * 2);
    border-top: var(--s-lines) solid var(--c-hover-bg);

    &:first-child {
      order: 2;
    }
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--c-hover-bg);
    transform: scaleY(0);
    transform-origin: left bottom;
    transition: transform var(--e-inout) 0.4s;
  }

  @include hover() {
    @include hover() {
      &:hover {
        color: var(--c-hover-text);

        &::before {
          transform: scaleY(1);
        }
      }
    }
  }

  &:first-child {
    border-right: var(--s-lines) solid var(--c-hover-bg);

    @include viewport('tablet-s') {
      border-right: 0;
    }
  }

  &__text-container {
    @include hover() {
      #{$root}:hover & {
        @include twerk-anime();
      }
    }
  }

  &__subtitle,
  &__title {
    position: relative;
    display: block;
    z-index: 1;
    transition: color linear 0.2s, transform var(--e-inout) 0.3s;
  }

  &__subtitle {
    font-size: var(--s-text-small);
    margin-bottom: 0.5em;

    @include hover() {
      #{$root}:hover & {
        transform: translateY(var(--s-text-translate));
      }
    }
  }

  &__title {
    @include fontTitle();

    line-height: var(--s-line-height-titles-small);
    transition-duration: 0.2s, 0.5s;

    @include hover() {
      #{$root}:hover & {
        transform: translateY(var(--s-text-translate));
      }
    }
  }

  &__arrow {
    --s-width: 2em;
    --s-height: 0.5em;
    --c: var(--c-text);
    --t: translate(-50%, 1em);

    position: relative;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    left: 50%;
    transform: var(--t);

    .is-black & {
      --c: var(--c-text-inverted);
    }

    @include hover() {
      --s-width: 0;
      --s-height: 0;
      --c: var(--c-hover-text);

      .is-black & {
        --c: var(--c-text);
      }

      position: absolute;
      margin-top: 0;
      margin-bottom: 0;
    }

    &--next {
      transform: var(--t) scaleX(-1);
    }

    @include hover() {
      #{$root}:hover & {
        --s-width: 2.8em;
        --s-height: 0.65em;
      }
    }
  }
}

.credits {
  font-size: calc(var(--s-text-small));
  padding: var(--s-gutter);
  padding-left: 0;

  &__text {
    display: block;
    transform: translateY(0.35em);
  }
}

.browser-warning {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;

  &__infos {
    position: fixed;
    width: 50%;
    left: 50%;
    bottom: var(--s-gutter);
    box-sizing: border-box;
    padding-right: var(--s-gutter);

    @include viewport('mobile-l') {
      width: 100%;
      left: 0;
      padding-left: var(--s-gutter);
      padding-right: var(--s-gutter);
    }
  }

  &__title {
    font-size: calc(var(--s-text-h2) * 0.75);
  }

  &__desc {
    margin-top: 1em;
    margin-bottom: 0;
  }

  &__logo {
    z-index: 1000;
    pointer-events: none;

    .logo__el {
      fill: white;
    }
  }
}

.transition-logo {
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  transition: opacity ease-out 0.2s;

  &.is-displayed {
    opacity: 1;
    transition-delay: 0.2s;
  }

  .logo__el {
    --c-text-inverted: var(--c-body-hex);

    fill: var(--c-text-inverted);
  }
}

.transition-title {
  @include fontTitle();

  --c-text-inverted: var(--c-body-hex);

  position: fixed;
  pointer-events: none;
  z-index: 999;
  bottom: var(--s-gutter);
  right: var(--s-gutter);
  line-height: var(--s-line-height-titles);
  color: var(--c-text-inverted);
  text-transform: uppercase;
  font-size: var(--s-logo);
  letter-spacing: 0.025em;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: bottom right;
  opacity: 0;
  transition: opacity ease-out 0.2s;

  &.is-displayed {
    opacity: 1;
    transition-delay: 0.2s;
  }
}

.transition-background {
  &__el-in,
  &__el-out {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    background-color: black;
  }
}

.animate-in {
  --s-translate-x: 0%;
  --s-translate-y: 0%;
  --s-translateYAnimateIn: 10vh;
  --d-delay: 0.25s;
  --d-delay-step: 0.2s;
  --d-in-opacity: 0.75s;
  --e-opacity: ease;
  --d-in-transform: 1s;
  --e-transform: var(--e-out);
  --delay-id: 0;

  opacity: 0;
  transform: translate(var(--s-translate-x), calc(var(--s-translate-y) + var(--s-translateYAnimateIn)));
  transition: opacity var(--e-opacity) var(--d-in-opacity), transform var(--e-transform) var(--d-in-transform);
  transition-delay: calc(var(--d-delay) + var(--delay-id) * var(--d-delay-step));

  .is-displayed & {
    opacity: 1;
    transform: translate(var(--s-translate-x), var(--s-translate-y));
  }

  @for $i from 1 to 10 {
    &__s#{$i} {
      --delay-id: #{$i};
    }
  }

  .is-chromeios & {
    transform: translate(var(--s-translate-x), var(--s-translate-y));
    transition: none;
    opacity: 1;
  }
}

.transition-wave-in-enter-active {
  transition: transform var(--e-inout) 0.5s;
  transform-origin: top left;
}

.transition-wave-in-enter,
.transition-wave-in-leave-to {
  transform: scaleY(0);
}

.transition-wave-out-enter-active {
  transform: scaleY(0);
  transition: transform var(--e-inout) 0.5s;
  transform-origin: bottom left;
}

.transition-wave-out-leave-active {
  transform: scaleY(0);
}

.transition-wave-out-enter {
  transform: scaleY(1);
}

.transition-logo-enter-active {
  transition: opacity var(--e-inout) 0.5s;
}

// helper classes
.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.visually-hidden a:focus,
.visually-hidden input:focus,
.visually-hidden button:focus {
  position: static;
  width: auto;
  height: auto;
}
